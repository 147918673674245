$primary: #5e81ac !default;
//$secondary:     $gray-600 !default;
$success: #A3BE8C !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        #BF616A !default;
//$light:         $gray-100 !default;
$dark:          #4c566a !default;

$font-size-base: 0.8rem;

@import '~bootstrap/scss/bootstrap.scss';

body {
  color: rgb(76, 86, 106);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.btn-primary {
  color: white !important;
}

.bg-dark {
  background-color: #4c566a !important;
}

.navigation .nav-link {
  color: $dark;
}

.text-dark {
  color: #4c566a !important;
}

.array-element:hover {
  background-color: $light;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}